import React from 'react'
import svg0 from "../../static/images/blogPageImages/cardImage/isometric0.svg"
import svg1 from "../../static/images/blogPageImages/cardImage/isometric1.svg"
import svg2 from "../../static/images/blogPageImages/cardImage/isometric2.svg"
import svg3 from "../../static/images/blogPageImages/cardImage/isometric3.svg"
import svg4 from "../../static/images/blogPageImages/cardImage/isometric4.svg"
import svg5 from "../../static/images/blogPageImages/cardImage/isometric5.svg"
import svg6 from "../../static/images/blogPageImages/cardImage/isometric6.svg"
import svg7 from "../../static/images/blogPageImages/cardImage/isometric7.svg"
import svg8 from "../../static/images/blogPageImages/cardImage/isometric8.svg"
import svg9 from "../../static/images/blogPageImages/cardImage/isometric9.svg"

const isometrics = [svg0, svg1, svg2, svg3, svg4, svg5, svg6, svg7, svg8, svg9]

const SvgImgSelector2 = ({ index }) => {
    //const randomIndex = Math.floor(Math.random() * isometrics.length)
    return <img src={isometrics[9 < index ? index.toString().slice(-1) : index]} alt="svg"/> 
}

export default SvgImgSelector2