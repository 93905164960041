import React from 'react'
import { Link, graphql } from "gatsby"
//import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/hero"
//import BlogRecommendation from "../components/blogRecommendation"
import BlogCategoryMenu from "../components/blogCategoryMenu"
import SvgImgSelector2 from "../components/svgImgSelector2"
import { blogBGColor } from "../utils/variables"
import "../../static/styles/blog2.scss"

const Blog2 =({ data }) => {
    const shortenData = data.allMarkdownRemark.edges
    return (
            <Layout page="Blog">
            <Seo title="Blog" description="" />
            <div>
              <PageHeader page="Blog"/>
              <div>
                  <BlogCategoryMenu/>
                  
                  <div className="blog-2-container">
                    {shortenData.map((x, index) => {
                      const { fakeData, date, excerpt, title, path, tags } = x.node.frontmatter;
                      return (
                          <Link to={path} key={index} style={{backgroundColor: blogBGColor[index.toString().slice(-1).replace("9", "0")]}}>
                              <div className="blog-card">      
                                  <div className="">
                                    <SvgImgSelector2 index={index}/>
                                  </div>
                                  <div className=""> 
                                      <h2>{title}</h2>
                                      <p>{excerpt}</p>
                                      <p>【 { tags[0] } 】<span>{fakeData ? fakeData : date} </span></p>
                                  </div> 
                              </div> 
                          </Link>
                      )
                    })}
                  </div>

              </div>
            </div>
        </Layout>
    );
};

export const blogIndex = graphql`
    query BlogPageQuery {
      allMarkdownRemark(
          sort: { fields: frontmatter___id, order: DESC }
      ) {
          edges {
            node {
              frontmatter {
                    id
                    path
                    fakeData
                    date
                    update
                    title
                    excerpt
                    description
                    tags
                    categoryTagJP
                    featuredImage {
                        childImageSharp {
                          gatsbyImageData(placeholder: BLURRED, quality: 10)
                        }
                    }
            }
          }
        }
      }
    }
  `

export default Blog2;