import React from 'react'
import { Link } from "gatsby"
import { allCategoriesJP, allCategoriesEN } from "../utils/variables"

const BlogCategoryMenu = () => {
    return (
        <div className="blogCategoryMenu-root">
            {allCategoriesJP.map((category, index) => 
                <Link to={`${category === "すべて" ? "/blog" : `/blog/category/${allCategoriesEN[index]}`}`} 
                      key={index}
                >
                {category}
                </Link>
            )}
        </div>
    )
}

export default BlogCategoryMenu